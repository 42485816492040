import {NgModule} from '@angular/core';
import {LogInComponent} from './components/log-in/log-in.component';
import {AuthRoutingModule} from './auth-routing.module';
import {OauthParameters} from './config/oauth-parameters';
import {LogOutComponent} from './components/log-out/log-out.component';
import {SharedModule} from '../shared/shared.module';
import {EffectsModule} from '@ngrx/effects';
import {AuthEffects} from './store/auth.effects';
import {StoreModule} from '@ngrx/store';
import * as fromAuth from './store/auth.reducer';


@NgModule({
    imports: [
        AuthRoutingModule,
        SharedModule,
        StoreModule.forFeature(('auth'), fromAuth.reducer),
        EffectsModule.forFeature([AuthEffects])
    ],
    declarations: [
        LogInComponent,
        LogOutComponent
    ],
    providers: [
        OauthParameters
    ]
})
export class AuthModule {
}
