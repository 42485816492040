import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {ActionReducer, MetaReducer, StoreModule} from '@ngrx/store';
import {APP_REDUCERS} from './store/app.reducers';
import {EffectsModule} from '@ngrx/effects';
import {AuthTokenService} from './auth/services/auth-token.service';
import {AppRoutingModule} from './app-routing.module';
import {AuthModule} from './auth/auth.module';
import {DashboardModule} from './dashboard/dashboard.module';
import {PageNotFoundComponent} from './shared/page-not-found/page-not-found.component';
import {HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import {AuthInterceptor} from './auth/auth-interceptor';
import {HttpLoaderFactory, SharedModule} from './shared/shared.module';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment} from '../environments/environment';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {localStorageSync} from 'ngrx-store-localstorage';
import {RouterStateUrl} from './store/router/router.state';
import {RouterStateSerializer, StoreRouterConnectingModule} from '@ngrx/router-store';
import {RouterStateSnapshot} from '@angular/router';
import {DatePipe, registerLocaleData} from '@angular/common';
import localePl from '@angular/common/locales/pl';
import localePlExtra from '@angular/common/locales/extra/pl';
import { UsersComponent } from './dashboard/clients/users/users.component';

export class CustomSerializer implements RouterStateSerializer<RouterStateUrl> {
    serialize(routerState: RouterStateSnapshot): RouterStateUrl {
        let route = routerState.root;
        while (route.firstChild) {
            route = route.firstChild;
        }
        const {url, root: {queryParams}} = routerState;
        const {params} = route;
        return {url, params, queryParams};
    }
}


export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({
        keys: ['auth', 'router'],
        rehydrate: true,
        removeOnUndefined: true
    })(reducer);
}

const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

registerLocaleData(localePl, 'pl', localePlExtra);


@NgModule({
    declarations: [
        AppComponent,
        PageNotFoundComponent,
    ],
    imports: [
        SharedModule,
        AuthModule,
        DashboardModule,
        AppRoutingModule,
        BrowserModule,
        StoreModule.forRoot(APP_REDUCERS, {metaReducers}),
        EffectsModule.forRoot([]),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
        }),
        ToastrModule.forRoot({
            closeButton: true
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        StoreRouterConnectingModule.forRoot({
            stateKey: 'router'
        }),
        BrowserAnimationsModule
    ],
    providers: [
        AuthTokenService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: RouterStateSerializer,
            useClass: CustomSerializer
        },
        DatePipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
