import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {switchMap, tap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AlertService {

    constructor(private toastr: ToastrService,
                private translate: TranslateService) {

    }

    success(message?: string, title?: string) {
        this.translate.get(message).pipe(
            switchMap((messageValue) => {
                return this.translate.get(title).pipe(
                    tap((titleValue) => {
                        this.toastr.success(messageValue, titleValue);
                    })
                );
            })
        ).subscribe();

    }

    warning(message?: string, title?: string) {
        this.translate.get(message).pipe(
            switchMap((messageValue) => {
                return this.translate.get(title).pipe(
                    tap((titleValue) => {
                        this.toastr.warning(messageValue, titleValue);
                    })
                );
            })
        ).subscribe();
    }

    info(message?: string, title?: string) {
        this.translate.get(message).pipe(
            switchMap((messageValue) => {
                return this.translate.get(title).pipe(
                    tap((titleValue) => {
                        this.toastr.info(messageValue, titleValue);
                    })
                );
            })
        ).subscribe();
    }

    error(message?: string, title?: string) {
        this.translate.get(message).pipe(
            switchMap((messageValue) => {
                return this.translate.get(title).pipe(
                    tap((titleValue) => {
                        this.toastr.error(messageValue, titleValue);
                    })
                );
            })
        ).subscribe();
    }

    clear(alertId?: number) {
        this.toastr.clear(alertId);
    }


}
