import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {OauthParameters} from '../config/oauth-parameters';
import {environment} from '../../../environments/environment';

@Injectable()
export class AuthTokenService {
    token;
    authHeaders: HttpHeaders;

    constructor(private http: HttpClient,
                private oauthParameters: OauthParameters) {
        this.authHeaders = this.createAuthHeaders();
    }

    private createAuthHeaders() {
        const authData64 = btoa(this.oauthParameters.clientId + ':' + this.oauthParameters.clientSecret);
        return new HttpHeaders()
            .set('Authorization', 'Basic ' + authData64)
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8');
    }

    public initToken(email: string, password: string): Observable<any> {
        const body = `grant_type=password&username=${email}&password=${password}`;
        return this.http.post(`${environment.restUrl}/token`, body, {headers: this.authHeaders});
    }

    public refreshToken(currentRefreshToken: string): Observable<any> {
        const body = `grant_type=refresh_token&refresh_token=${currentRefreshToken}`;
        return this.http.post(`${environment.restUrl}/token`, body, {headers: this.authHeaders});
    }

}
