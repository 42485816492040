import {ActionReducerMap} from '@ngrx/store';
import {routerReducer, RouterReducerState} from '@ngrx/router-store';
import {RouterStateUrl} from './router/router.state';

export interface AppState {
    router: RouterReducerState<RouterStateUrl>;
}

export const APP_REDUCERS: ActionReducerMap<AppState, any> = {
    router: routerReducer,
};
