import {Action} from '@ngrx/store';
import {AuthTokens} from '../model/auth-token';
import {UserLoginCredentials} from '../model/user-login-credentials';

export enum AuthActionTypes {
    Login = '[Auth] Login',
    LoginSuccess = '[Auth] LoginSuccess',
    LoginFail = '[Auth] LoginFailed',
    Logout = '[Auth] Logout',
    RefreshToken = '[Auth] RefreshToken',
    RefreshTokenFail = '[Auth] RefreshTokenFail',
    RefreshTokenSuccess = '[Auth] RefreshTokenSuccess'
}

export class Login implements Action {
    readonly type = AuthActionTypes.Login;

    constructor(public payload: UserLoginCredentials) {
    }
}

export class LoginSuccess implements Action {
    readonly type = AuthActionTypes.LoginSuccess;

    constructor(public payload: AuthTokens) {
    }
}

export class LoginFail implements Action {
    readonly type = AuthActionTypes.LoginFail;

    constructor(public payload: any) {
    }
}

export class RefreshToken implements Action {
    readonly type = AuthActionTypes.RefreshToken;

    constructor(public payload: AuthTokens) {
    }
}

export class RefreshTokenSuccess implements Action {
    readonly type = AuthActionTypes.RefreshTokenSuccess;

    constructor(public payload?: any) {
    }
}

export class RefreshTokenFail implements Action {
    readonly type = AuthActionTypes.RefreshTokenFail;

    constructor(public payload?: any) {
    }
}

export class Logout implements Action {
    readonly type = AuthActionTypes.Logout;

    constructor(public payload?: any) {
    }
}

export type AuthActions =
    Login
    | LoginSuccess
    | LoginFail
    | Logout
    | RefreshToken
    | RefreshTokenFail
    | RefreshTokenSuccess;
