import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {AppState} from '../store/app.reducers';
import {Store} from '@ngrx/store';
import {map} from 'rxjs/operators';
import {isUndefined} from 'util';
import {OauthParameters} from './config/oauth-parameters';
import * as fromAuth from './../auth/store/auth.reducer';


@Injectable()
export class RouteAccessResolver implements CanActivate, CanActivateChild, CanLoad {

    constructor(private store: Store<AppState>,
                private router: Router,
                private oauthParameters: OauthParameters) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.authenticate();
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.authenticate();
    }

    canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
        return this.authenticate();
    }

    authenticate(): Observable<boolean> | Promise<boolean> | boolean {
        return this.store.select('auth')
            .pipe(
                map((state: fromAuth.State) => {
                    if (!isUndefined(state)) {
                        if (isUndefined(state.token) || !state.isAuthenticated) {
                            this.handleLogout();
                            return false;
                        } else {
                            return true;
                        }
                    } else {
                        this.handleLogout();
                        return false;
                    }
                })
            );
    }

    handleLogout() {
        const backUrl = window.location.href.replace(window.location.origin, '');
        if (![this.oauthParameters.loginPath, this.oauthParameters.logoutPath].includes(backUrl)) {
            sessionStorage.setItem(this.oauthParameters.sessionStorageLoginRedirectUrl, backUrl);
        }
        this.router.navigate([this.oauthParameters.loginPath]);
    }

}
