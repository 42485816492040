import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {AuthActionTypes, Login, LoginFail, LoginSuccess, RefreshToken, RefreshTokenFail, RefreshTokenSuccess} from './auth.actions';
import {catchError, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {Store} from '@ngrx/store';
import {isUndefined} from 'util';
import {Router} from '@angular/router';
import {AlertService} from '../../services/alert.service';
import {AppState} from '../../store/app.reducers';
import {AuthTokens} from '../model/auth-token';
import {OauthParameters} from '../config/oauth-parameters';
import {AuthTokenService} from '../services/auth-token.service';
import {MatDialog} from '@angular/material';


@Injectable()
export class AuthEffects {

    constructor(private actions$: Actions,
                private store$: Store<AppState>,
                private router: Router,
                private dialogRef: MatDialog,
                private alertService: AlertService,
                private oauthParameters: OauthParameters,
                private authTokenService: AuthTokenService) {
    }

    @Effect()
    refreshToken = this.actions$
        .pipe(
            ofType(AuthActionTypes.RefreshToken),
            map((action: RefreshToken) => {
                return action.payload;
            }),
            switchMap((payload: AuthTokens) => {
                return this.authTokenService.refreshToken(payload.refresh_token)
                    .pipe(
                        map((response: AuthTokens) => new RefreshTokenSuccess(response)),
                        catchError((error) => of(new RefreshTokenFail(error)))
                    );
            })
        );

    @Effect()
    authLogin = this.actions$
        .pipe(
            ofType(AuthActionTypes.Login),
            map((action: Login) => {
                return action.payload;
            }),
            switchMap((payload) => {
                return this.authTokenService.initToken(payload.username, payload.password)
                    .pipe(
                        map((response: AuthTokens) => new LoginSuccess(response)),
                        catchError((error) => of(new LoginFail(error)))
                    );
            })
        );

    @Effect({dispatch: false})
    successAuth = this.actions$
        .pipe(
            ofType(AuthActionTypes.LoginSuccess),
            withLatestFrom(this.store$),
            tap(() => {
                let redirectHashUri = sessionStorage.getItem(this.oauthParameters.sessionStorageLoginRedirectUrl);
                sessionStorage.removeItem(this.oauthParameters.sessionStorageLoginRedirectUrl);
                if (isUndefined(redirectHashUri) || !redirectHashUri) {
                    redirectHashUri = '/';
                }
                this.router.navigate([redirectHashUri]);
                this.alertService.success('AUTH.ALERTS.Login.SUCCESS.MESSAGE', 'AUTH.ALERTS.Login.SUCCESS.TITLE');
            })
        );

    @Effect({dispatch: false})
    loginFail = this.actions$
        .pipe(
            ofType(AuthActionTypes.LoginFail),
            withLatestFrom(this.store$),
            tap((action) => {
                this.alertService.error('AUTH.ALERTS.Login.FAIL.MESSAGE', 'AUTH.ALERTS.Login.FAIL.TITLE');
            })
        );


    @Effect({dispatch: false})
    logout = this.actions$
        .pipe(
            ofType(AuthActionTypes.Logout),
            withLatestFrom(this.store$),
            tap((action) => {
                this.router.navigate([this.oauthParameters.logoutPath]);
                this.alertService.success('AUTH.ALERTS.Logout.MESSAGE', 'AUTH.ALERTS.Logout.TITLE');
                this.dialogRef.closeAll();
            })
        );

}
