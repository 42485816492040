import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.reducers';
import {Logout} from '../../../auth/store/auth.actions';

export interface Route {
    path: string;
    icon: string;
    text: string;
}

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
    routes: Route[] = [];

    constructor(private store$: Store<AppState>) {
    }

    ngOnInit() {
        this.setRoutes();
    }

    setRoutes() {
        this.routes = [
            {path: '/profile', icon: 'build', text: 'ROUTES.PROFILE'},
            {path: '/supervisors', icon: 'supervisor_account', text: 'ROUTES.SUPERVISORS'},
            {path: '/pricings', icon: 'credit_card', text: 'ROUTES.PRICING'},
            {path: '/terms', icon: 'event_note', text: 'ROUTES.TERMS'},
            {path: '/companies', icon: 'business', text: 'ROUTES.COMPANIES'},
            {path: '/teams', icon: 'group', text: 'ROUTES.TEAMS'},
            {path: '/users', icon: 'person', text: 'ROUTES.USERS'},
            // {path: '/billings', icon: 'shopping_cart', text: 'ROUTES.BILLINGS'},
            // {path: '/payments', icon: 'payment', text: 'ROUTES.PAYMENTS'},
            // {path: '/stats', icon: 'bar_chart', text: 'ROUTES.STATS'},
        ];
    }

    logout() {
        this.store$.dispatch(new Logout());
    }
}
