import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-active-badge',
    templateUrl: './active-badge.component.html',
    styleUrls: ['./active-badge.component.scss']
})
export class ActiveBadgeComponent {

    @Input()
    active: boolean;

    constructor() {
    }

}
