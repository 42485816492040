import {AuthTokens} from '../model/auth-token';
import {AuthActions, AuthActionTypes} from './auth.actions';
import {createFeatureSelector, createSelector} from '@ngrx/store';

export interface State {
    isAuthenticated: boolean;
    token?: AuthTokens;
    user?: any;
    authError?: any;
}

export const initialState: State = {
    isAuthenticated: false
};


export function reducer(
    state = initialState,
    action: AuthActions
): State {
    switch (action.type) {
        case AuthActionTypes.LoginSuccess:
            return {
                ...state,
                isAuthenticated: true,
                token: action.payload
            };
        case AuthActionTypes.LoginFail:
            return {
                ...state,
                isAuthenticated: false,
                authError: action.payload.error.error
            };
        case AuthActionTypes.Logout:
            return {
                ...initialState
            };
        case AuthActionTypes.RefreshTokenSuccess:
            return {
                ...state,
                isAuthenticated: true,
                token: action.payload
            };
        case AuthActionTypes.RefreshTokenFail:
            return {
                ...initialState
            };
        default:
            return state;
    }
}

export const selectAuth = createFeatureSelector<State>('auth');

export const selectAuthToken = createSelector(selectAuth, (state: State) => {
    return state.token;
});

