import {HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, of, Subject, throwError} from 'rxjs';
import {catchError, switchMap, take, tap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {AuthTokens} from './model/auth-token';
import {Injectable} from '@angular/core';
import {AuthTokenService} from './services/auth-token.service';
import {AppState} from '../store/app.reducers';
import {select, Store} from '@ngrx/store';
import {Logout, RefreshTokenSuccess} from './store/auth.actions';
import {selectAuthToken} from './store/auth.reducer';
import {OauthParameters} from './config/oauth-parameters';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    refreshTokenInProgress = false;
    tokenRefreshedSource = new Subject();
    tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

    constructor(private authService: AuthTokenService,
                private store$: Store<AppState>,
                private oauthParameters: OauthParameters) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        return this.store$.pipe(
            take(1),
            select(selectAuthToken),
            switchMap((token: AuthTokens) => {
                return next.handle(this.addAuthHeaders(request, token)).pipe(
                    catchError((error) => {
                        if (error.status === 401) {
                            return this.refreshToken(token.refresh_token).pipe(
                                switchMap((newToken: AuthTokens) => {
                                    return next.handle(this.addAuthHeaders(request, newToken));
                                }), catchError(() => {
                                    this.store$.dispatch(new Logout());
                                    return of();
                                }));
                        }
                        return throwError(error);
                    }));
            })
        );
    }

    private refreshToken(refreshToken) {
        if (this.refreshTokenInProgress) {
            return new Observable(observer => {
                this.tokenRefreshed$
                    .subscribe(() => {
                        observer.next();
                        observer.complete();
                    });
            });
        } else {
            this.refreshTokenInProgress = true;
            return this.authService.refreshToken(refreshToken)
                .pipe(
                    tap(token => {
                        this.authService.token = token;
                        this.store$.dispatch(new RefreshTokenSuccess(token));
                        this.refreshTokenInProgress = false;
                        this.tokenRefreshedSource.next();
                    })
                );
        }
    }

    private isBackendUrl(request: HttpRequest<any>) {
        return request.url.substr(0, environment.restUrl.length) == environment.restUrl;
    }

    private isTokenEndpoint(request: HttpRequest<any>) {
        const url = request.url.replace(environment.restUrl, '');
        return url == '/token';
    }

    addAuthHeaders(request: HttpRequest<any>, token: AuthTokens) {
        if (this.isBackendUrl(request)) {
            const url: string = request.url.replace(environment.restUrl, '');
            const basic = this.oauthParameters.clientId + ':' + this.oauthParameters.clientSecret;
            const authHeader = (url === '/token') ? ('Basic ' + btoa(basic)) : ('Bearer ' + token.access_token);
            let modifiedHeaders;

            if (url === '/token') {
                modifiedHeaders = new HttpHeaders()
                    .set('Authorization', authHeader)
                    .set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8');
            } else {
                modifiedHeaders = new HttpHeaders()
                    .set('Authorization', authHeader);
            }
            if (request.headers.get('content-type') == null) {
                modifiedHeaders.append('Content-Type', 'application/json');
            } else {
                modifiedHeaders.append('Content-Type', request.headers.get('content-type'));
            }
            return request.clone({headers: modifiedHeaders});
        }

        return request;
    }

}
