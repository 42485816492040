import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { LogInComponent } from './components/log-in/log-in.component';
import { LogOutComponent } from './components/log-out/log-out.component';

const moduleRoutes: Routes = [
    {path: 'login', component: LogInComponent},
    {path: 'logout', component: LogOutComponent}
];

@NgModule({
    imports: [
        RouterModule.forChild(moduleRoutes)
    ],
    exports: [RouterModule]
})
export class AuthRoutingModule {
}
