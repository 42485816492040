import {DashboardComponent} from './dashboard.component';
import {NgModule} from '@angular/core';
import {DashboardRoutingModule} from './dashboard-routing.module';
import {SharedModule} from '../shared/shared.module';
import {SidebarComponent} from './layout/sidebar/sidebar.component';
import {HeaderComponent} from './layout/header/header.component';

@NgModule({
    imports: [
        SharedModule,
        DashboardRoutingModule
    ],
    declarations: [
        DashboardComponent,
        SidebarComponent,
        HeaderComponent,
    ],
    providers: [],
    entryComponents: []
})
export class DashboardModule {
}
