import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { Login } from '../../store/auth.actions';

@Component({
    selector: 'app-log-in',
    templateUrl: './log-in.component.html',
    styleUrls: ['./log-in.component.scss']
})
export class LogInComponent implements OnInit {

    public loginForm: FormGroup;

    constructor(private formBuilder: FormBuilder,
                private store: Store<AppState>) {
    }

    ngOnInit() {
        this.createForm();
    }

    createForm() {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });
    }

    login() {
        const model = this.loginForm.value;
        this.store.dispatch(new Login({username: model.username, password: model.password}));
    }

}
