import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RouteAccessResolver} from '../auth/route-access-resolver';
import {DashboardComponent} from './dashboard.component';

const moduleRoutes: Routes = [
    {
        path: '', component: DashboardComponent, canActivate: [RouteAccessResolver],
        children: [
            {path: '', pathMatch: 'full', redirectTo: 'companies'},
            {path: '', loadChildren: './administration/administration.module#AdministrationModule'},
            {path: '', loadChildren: './clients/clients.module#ClientsModule'},
            {path: '', loadChildren: './settings/settings.module#SettingsModule'},
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(moduleRoutes)
    ],
    providers: [RouteAccessResolver],
    exports: [RouterModule]
})
export class DashboardRoutingModule {
}
