import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ActiveBadgeComponent} from './active-badge/active-badge.component';
import {SpinnerComponent} from './spinner/spinner.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule, MatSlideToggleModule,
    MatSortModule,
    MatTableModule,
    MatToolbarModule,
    MatTooltipModule
} from '@angular/material';
import {SidebarModule} from 'ng-sidebar';
import {TranslateModule} from '@ngx-translate/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {MatTabsModule} from '@angular/material/tabs';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {NgxCurrencyModule} from 'ngx-currency';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatDatepickerModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatNativeDateModule,
        MatListModule,
        MatPaginatorModule,
        MatSelectModule,
        MatSidenavModule,
        MatSortModule,
        MatTableModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTabsModule,
        MatDividerModule,
        NgxChartsModule,
        MatDialogModule,
        SidebarModule.forRoot(),
        TranslateModule,
        HttpClientModule,
        MatCheckboxModule,
        MatRadioModule,
        MatExpansionModule,
        NgxCurrencyModule,
        MatSlideToggleModule
    ],
    declarations: [
        ActiveBadgeComponent,
        SpinnerComponent
    ],
    exports: [
        CommonModule,
        ActiveBadgeComponent,
        SpinnerComponent,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatDatepickerModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatNativeDateModule,
        MatListModule,
        MatPaginatorModule,
        MatSelectModule,
        MatSidenavModule,
        MatSortModule,
        MatTableModule,
        MatToolbarModule,
        MatTooltipModule,
        SidebarModule,
        TranslateModule,
        HttpClientModule,
        MatDialogModule,
        MatTabsModule,
        MatDividerModule,
        NgxChartsModule,
        MatCheckboxModule,
        MatRadioModule,
        MatExpansionModule,
        NgxCurrencyModule,
        MatSlideToggleModule
    ],
})
export class SharedModule {
}
